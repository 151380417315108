.login {
  background-repeat: no-repeat;
  width: 100%;
}

.loginpan {
  width: 500px;
  height: 100vh;
  top: 0px;
  padding: 0 30px;
  margin: auto;
}
.loginlogo {
  width: 100%;
  text-align: center;
  margin: 90px 0 0 0;
}
.loginlogo img {
  margin-right: 35px;
  max-width: 160px;
}
.loginlogo p {
  color: #93a4b7;
  font-size: 11px;
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
  width: 100%;
  text-align: left;
}
.login .formrow {
  border-radius: 4px;
  box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.13);
  border: solid 1px #ececec;
  padding: 28px 50px;
  margin: 30px auto;
  width: 440px;
  background: #fff;
}
.login .formrow .form-control {
  background: none;
  border: 1px solid #6a7a8c59;
  width: 100%;
  height: 40px;
  padding: 0 10px 0 50px;
  margin-bottom: 10px;
  border-radius: 0px;
}

.forget {
  padding-top: 30px;
}
.forget p {
  font-size: 14px;
  color: #000;
  line-height: 35px;
}

.forget p a {
  text-decoration: none;
  color: #00ab5b;
  border-bottom: 1px solid #00ab5b;
}

.login .formrow .btn-primary {
  width: 100%;
  background-image: linear-gradient(to bottom, #00a65a, #17d27c);
  padding: 8px 0;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 12px;
  color: #fff;
  border: 0px;
}
.btn-primary:active,
.btn-primary:focus {
  outline: 0px;
  box-shadow: 0 0 0 0 rgb(255 255 255 / 50%);
  border: 0px;
  background: inherit;
}
.formrow h2 {
  text-align: center;
  font-size: 25px;
  margin-bottom: 20px;
  letter-spacing: 0px;
  color: #1c293b;
  /* text-transform: uppercase; */
}
.login .formrow .btn-primary:disabled {
  opacity: 0.15;
}
.login .formrow .user_icon {
  position: relative;
}
.login .formrow .user_icon::after {
  background: url(../images/envelope.svg);
  background-repeat: no-repeat;
  background-position: 10px center;
  content: '';
  position: absolute;
  width: 44px;
  height: 30px;
  left: 0;
  top: 38px;
}
.login .formrow .pass_icon {
  position: relative;
}

.login .formrow .pass_icon::after {
  background: url(../images/lock.svg);
  background-repeat: no-repeat;
  background-position: 10px center;
  content: '';
  position: absolute;
  width: 44px;
  height: 30px;
  left: 0;
  top: 38px;
}
