/* FormDemo.css */
.compliaces #driver{
  width: auto;
}
.form-demo .card {
  min-width: 450px;
}
.form-demo .card form {
  margin-top: 2rem;
}
.form-demo .card .field {
  margin-bottom: 1.5rem;
}
@media screen and (max-width: 960px) {
  .form-demo .card {
    width: 80%;
  }
}
@media screen and (max-width: 640px) {
  .form-demo .card {
    width: 100%;
    min-width: 0;
  }
}

.p-chip.custom-chip {
  border: 2px solid;
  border-color: var(--primary-color);
  color: var(--text-color-secondary);
  background: white;
}

.green-tooltip.p-tooltip .p-tooltip-arrow {
  border-top-color: #b9dfc0;
}
.green-tooltip.p-tooltip .p-tooltip-text {
  background-color: #b9dfc0;
}
.itemrowdata{
  position: relative;
  
}
.itemrow{
  padding: 20px 0 0 0;
}
.itemrowdata .custompos{
  position: absolute;
  right: 0;
  top: 40px;
  z-index: 9;
}
.itemrowdata .custompos2{
  position: absolute;
  right: 50px;
  top: 40px;
  z-index: 9;
}