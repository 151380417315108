.grid-multi-select-wrapper {
  width: 450px !important;
}

.green-tooltip.p-tooltip .p-tooltip-arrow {
  border-top-color: var(--green-100);
}
.green-tooltip.p-tooltip .p-tooltip-text {
  background: var(--green-100);
  color: #495057;
}
